import React, {useState, useEffect} from 'react'
import ResidentSentimentForecaster from './ResidentSentimentForecaster'
import {createFeedbackForResident, skipForecastForResident} from '../../../actions/feedbackActions'
import {useDispatch, useSelector} from 'react-redux'
import {selectAuthedUserId} from '../../../reducers/selectors'
import useResidentNeedsSentimentForecast from '../../hooks/useResidentNeedsSentimentForecast'
import {
  FEEDBACK_TYPE_OVERALL_SENTIMENT,
  FEEDBACK_TYPE_PROBABILITY_OF_RENEWAL,
  CORE_FEATURE_DATA_COLLECTION,
} from '../../../constants/ModelConstants'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {Colors, Spacing} from '../../../assets/styles'
import {borderRadiusMedium} from '../../../assets/styles/border'
import moment from 'moment'
import {constructClassString} from '../../../Utils/objectUtil'
import useDoOnceTimer from '../../hooks/useDoOnceTimer'
import RXRIcon from '../../RXRIcon'

const HIDE_TIMER = 'hide-timer'
const HIDE_DELAY = 500
function ResidentForecasterCard(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const authedStaffId = useSelector(selectAuthedUserId)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const {isNeeded: showForecaster, lastForecastedAt} = useResidentNeedsSentimentForecast(props.residentId)

  const [isShowing, setIsShowing] = useState(showForecaster)
  const {setTimer} = useDoOnceTimer()
  const [startingHeight, setStartingHeight] = useState(0)
  const [haSubmittedFeedback, setHaSubmittedFeedback] = useState(false)

  const coreFeatures = useSelector(state => state.GroupPermissions.coreFeatures)
  const feedbackDisabled = !coreFeatures[CORE_FEATURE_DATA_COLLECTION]

  useEffect(() => {
    setIsShowing(showForecaster)
  }, [props.residentId])

  useEffect(() => {
    if (!showForecaster && isShowing) {
      setTimer(
        HIDE_TIMER,
        () => {
          setIsShowing(false)
        },
        HIDE_DELAY,
      )
    }
  }, [showForecaster, isShowing])

  const handleSubmitForecast = (residentId, renewalProbability, sentimentScore) => {
    return Promise.all([
      createFeedbackForResident(
        dispatch,
        activeBuildingId,
        authedStaffId,
        props.residentId,
        FEEDBACK_TYPE_OVERALL_SENTIMENT,
        sentimentScore,
      ),
      createFeedbackForResident(
        dispatch,
        activeBuildingId,
        authedStaffId,
        props.residentId,
        FEEDBACK_TYPE_PROBABILITY_OF_RENEWAL,
        renewalProbability,
      ),
    ]).then(() => {
      setHaSubmittedFeedback(true)
    })
  }

  return (
    !!props.residentId &&
    !feedbackDisabled && // if feedback is disabled, this card should never render
    (props.alwaysOn || isShowing) && (
      <div
        className={constructClassString(classes.container, props.className, {[classes.hide]: !showForecaster && isShowing})}
        style={{height: startingHeight}}
      >
        <div style={{padding: Spacing.spaceMedium}} ref={e => e && !startingHeight && setStartingHeight(e.getBoundingClientRect().height)}>
          <div className={classes.header}>
            <h3>Superforecaster</h3>
            <div className={classes.lastUpdated}>
              Last updated: {lastForecastedAt ? moment(lastForecastedAt).format('MMM D, YYYY') : 'Never'}
            </div>
          </div>
          {haSubmittedFeedback && props.alwaysOn ? (
            <div className={classes.confirmation}>
              <div>
                <div className={classes.confirmationIconContainer}>
                  <RXRIcon icon={RXRIcon.CHECK} color={Colors.rxrTealColor} size={ICON_SIZE / 2} />
                </div>
                <h3>Feedback received, thank you!</h3>
              </div>
            </div>
          ) : null}
          <ResidentSentimentForecaster
            residentId={props.residentId}
            compact={true}
            onSubmit={handleSubmitForecast}
            skipText={'Skip this time'}
            onSkip={props.alwaysOn ? undefined : () => skipForecastForResident(dispatch, props.residentId).then()}
          />
        </div>
      </div>
    )
  )
}

const ICON_SIZE = 100

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: borderRadiusMedium,
    backgroundColor: Colors.nearWhiteColor,
    border: `1px solid ${Colors.rxrGreyColor}`,
    overflow: 'hidden',
    position: 'relative',
  },
  header: {
    position: 'relative',
    zIndex: 11, // one higher than confirmation screen
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: Spacing.spaceMedium,

    '& h3': {
      textTransform: 'uppercase',
      color: Colors.rxrMediumGreyColor,
    },
  },
  lastUpdated: {
    color: Colors.rxrMediumGreyColor,
  },
  hide: {
    transition: `height ${HIDE_DELAY}ms ease-in-out`,
    height: `0 !important`,
  },

  confirmation: {
    padding: Spacing.spaceMedium,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: Colors.nearWhiteColor,
  },

  confirmationIconContainer: {
    borderRadius: '50%',
    margin: `0 auto ${Spacing.spaceMedium}px`,
    width: ICON_SIZE,
    height: ICON_SIZE,
    backgroundColor: Colors.rxrTeal25to100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    animation: '$animateCheck 0.6s ease-in-out',
  },

  '@keyframes animateCheck': {
    '0%': {
      transform: 'rotate(0deg) scale(0)',
    },
    '100%': {
      opacity: 1,
      transform: 'rotate(360deg) scale(1)',
    },
  },
}))

ResidentForecasterCard.propTypes = {
  residentId: PropTypes.string.isRequired,
  alwaysOn: PropTypes.bool,
  className: PropTypes.string,
}

export default ResidentForecasterCard
