import React, {useState, useEffect} from 'react'
import {Grid} from '@mui/material'
import SelectInput from '../../SelectInput'
import useFormChanged, {NonEmptyStringValidator, DateStringValidator} from '../../hooks/useFormChanged'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {FormComponentPropTypes} from '../onboardingTypes'
import {getAmenityCalendar} from '../../../lib/queries'
import moment from 'moment'
import {convertAvailabilityToTimeRangeOptions} from '../../Amenities/amenityCalendarHelpers'
import DateInput from '../../DateInput'
import useSharedStyles from '../useSharedStyles'
import {
  cancelAmenityReservation,
  createAmenityReservation,
  updateAmenityReservation,
  loadAmenitiesForBuilding,
} from '../../../actions/amenitiesActions'
import SaveOrEditFormCTAs from '../FormCTAs/SaveOrEditFormCTAs'
import {isOnboardingTaskStateCompleted, isOnboardingTaskStatePendingApproval} from '../../../Utils/onboardingUtils'
import {ONBOARDING_STATE_COMPLETED, ONBOARDING_STATE_COMPLETED_SKIPPED} from '../../../constants/ModelConstants'
import {getOnboardingOccupanciesLookup} from '../../../reducers/selectors'
import {spaceMedium} from '../../../assets/styles/spacing'
import DashboardToggle from '../../DashboardToggle'
import AcceptOrEditFormCTAs from '../FormCTAs/AcceptOrEditFormCTAs'

const EMPTY_FORM = {
  amenityId: null,
  startAt: null,
  isKeyPickupNeeded: true,
  partySize: 1, // always party size 1
}

function KeyPickup(props) {
  const sharedClasses = useSharedStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [bookingDuration, setBookingDuration] = useState(0)
  const [options, setOptions] = useState([])

  const {setForm, form, formChanged, validateForm, invalidItems, resetInitialForm} = useFormChanged(
    EMPTY_FORM,
    useFormChanged.PropLevelValidation({
      amenityId: NonEmptyStringValidator,
      startAt: DateStringValidator,
    }),
  )

  const onboardingOccupanciesLookup = useSelector(state => getOnboardingOccupanciesLookup(state), shallowEqual)
  const onboardingOccupancy = onboardingOccupanciesLookup[props.task.occupancyId]

  const [reservationDate, setReservationDate] = useState(
    new Date(Math.max(moment().add(1, 'day').startOf('day').toDate(), moment(onboardingOccupancy.leaseFromDate).startOf('day').toDate())),
  )
  const [isEditMode, setIsEditMode] = useState(false)
  const dispatch = useDispatch()

  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)

  // NOTE: we don't need to load Amenity Reservations here because that request is already made periodically in BackgroundDataSync
  const amenityReservationsLookup = useSelector(state => state.Amenities.amenityReservationsLookup)

  const loadedReservation = props.submission.amenityReservationId
    ? amenityReservationsLookup[props.submission.amenityReservationId]
    : undefined

  useEffect(() => {
    if (loadedReservation && options.length) {
      const startAt = options.find(option => option.value === loadedReservation.startAt) ? loadedReservation.startAt : options[0].value
      resetInitialForm({...loadedReservation, startAt, isKeyPickupNeeded: true})
      setReservationDate(moment(loadedReservation.startAt).toDate())
    } else if (props.submission.isKeyPickupNeeded === false && !isEditMode) {
      resetInitialForm({...EMPTY_FORM, isKeyPickupNeeded: props.submission.isKeyPickupNeeded})
    }
  }, [loadedReservation, options, props.submission.isKeyPickupNeeded])

  const taskMeta = JSON.parse(props.task.taskMeta)
  const keyPickupAmenityId = taskMeta.amenityId
  const keyPickUpAmenity = amenitiesLookup[keyPickupAmenityId]

  useEffect(() => {
    if (form.isKeyPickupNeeded && props.submission.isKeyPickupNeeded === false) {
      // If we are editing a reservation with no key pickup needed to
      // a form that is keyPickUpNeeded, then we need to set the form's amenityId
      // to the keyPickupAmenityId
      setForm({amenityId: keyPickupAmenityId})
    }
  }, [form.isKeyPickupNeeded])

  // if the number of elevator options changes (or when initialized) we need to change our form to match
  useEffect(() => {
    if (!keyPickUpAmenity || !keyPickUpAmenity.id) {
      // if there is no key pick up amenity label, it's probably because we haven't loaded amenities yet
      setIsLoading(true)
      loadAmenitiesForBuilding(dispatch, activeBuildingId).then(() => {
        setIsLoading(false)
      })
    } else {
      setForm({amenityId: keyPickUpAmenity.id})
    }
  }, [keyPickUpAmenity])

  // whenever the date or amenity changes, we need to reload the availability for that day & resource
  useEffect(() => {
    if (!form.amenityId || !reservationDate || isLoading) {
      return
    }

    const fetchAmenityCalendar = async () => {
      try {
        setIsLoading(true)
        const amenityCalendarDetail = await getAmenityCalendar(
          form.amenityId,
          props.submission.residentId,
          form.partySize,
          moment(reservationDate).startOf('day').toDate(),
          moment(reservationDate).endOf('day').toDate(),
        )

        setBookingDuration(amenityCalendarDetail.minReservationDuration)
        const timeOptions = convertAvailabilityToTimeRangeOptions(
          amenityCalendarDetail.availability,
          amenityCalendarDetail.generalAvailability,
          amenityCalendarDetail.reservationIncrement,
          amenityCalendarDetail.minReservationDuration,
        )

        setOptions(
          timeOptions.map(t => ({
            ...t,
            label: `${t.label} - ${moment(t.value).add(amenityCalendarDetail.minReservationDuration, 'minutes').format('hh:mm A')}`,
          })),
        )

        // determine what the timestamp would be for the new date at the same time
        const selectedTimeInMinutes = moment(form.startAt).diff(moment(form.startAt).startOf('day'), 'minutes')
        const thisTimeOnNewDay = moment(reservationDate).startOf('day').add(selectedTimeInMinutes, 'minutes').toISOString()

        // if that time is in our timeOptions list, we update our startAt accordingly
        if (timeOptions.some(t => t.value === thisTimeOnNewDay)) {
          setForm({startAt: thisTimeOnNewDay})
        } else {
          // if it's not, we reset the startAt to be empty
          setForm({startAt: null})
        }
      } catch (err) {
        console.error(err)
        window.alert('Error getting amenity calendar: ' + err.message)
      } finally {
        setIsLoading(false)
      }
    }

    fetchAmenityCalendar().then()
  }, [reservationDate.toISOString(), form.amenityId])

  const handleSave = async () => {
    // if the elevator is no longer needed, but we have a reservation loaded, we need to cancel it

    if (!form.isKeyPickupNeeded && form.id) {
      await cancelAmenityReservation(dispatch, form.id)
    }

    // at this point, we know we want to create or update an existing reservation,
    // make sure the form is valid to save
    if (!validateForm() && form.isKeyPickupNeeded) {
      return
    }

    try {
      let newReservationData
      const sharedInput = {
        startAt: form.startAt,
        endAt: moment(form.startAt).add(bookingDuration, 'minutes').toDate(),
        partySize: form.partySize,
      }

      // if they're changing the amenity, we first cancel the existing one and create a new one instead
      if ((loadedReservation && form.amenityId !== loadedReservation.amenityId) || !form.isKeyPickupNeeded) {
        await cancelAmenityReservation(dispatch, form.id)
        // delete the form.id so the next condition knows to create a new one
        delete form.id
      }

      if (form.isKeyPickupNeeded) {
        if (form.id) {
          // if we already have a reservation id, we're updating an existing reservation
          newReservationData = await updateAmenityReservation(dispatch, {...sharedInput, id: form.id})
        } else {
          // otherwise, we're creating a new reservation
          newReservationData = await createAmenityReservation(dispatch, {
            ...sharedInput,
            amenityId: form.amenityId,
            residentId: props.submission.residentId,
            partySize: 1,
            notes: 'Key pick-up reservation',
          })
        }
      }

      if (form.isKeyPickupNeeded) {
        await props.onUpdateSubmission({
          ...props.submission,
          amenityReservationId: newReservationData.id,
          reservationStartAt: newReservationData.startAt,
          reservationEndAt: newReservationData.endAt,
          state: ONBOARDING_STATE_COMPLETED,
        })
      } else {
        await props.onUpdateSubmission({
          ...props.submission,
          amenityReservationId: null,
          reservationStartAt: null,
          reservationEndAt: null,
          state: ONBOARDING_STATE_COMPLETED_SKIPPED,
        })
      }

      setIsEditMode(false)
      // NOTE: we don't need to resetInitialForm here because it'll be handled by the loadedReservation useEffect once it realizes the submission object has changed
    } catch (err) {
      console.error(err)
      window.alert(err.message)
    }
  }

  const markSubmissionCompleted = async () => {
    if (form.isKeyPickupNeeded) {
      await props.onUpdateSubmission({
        ...props.submission,
        state: ONBOARDING_STATE_COMPLETED,
      })
    } else {
      await props.onUpdateSubmission({
        ...props.submission,
        state: ONBOARDING_STATE_COMPLETED_SKIPPED,
      })
    }
  }

  const taskRequiresApproval = props.task.requiresApproval && isOnboardingTaskStatePendingApproval(props.submission.state)
  const canEditFields = isEditMode || (!isOnboardingTaskStateCompleted(props.submission.state) && !taskRequiresApproval)
  return (
    <React.Fragment>
      <div className={sharedClasses.bodyContainer}>
        <DashboardToggle
          onChange={v => setForm({isKeyPickupNeeded: v})}
          value={form.isKeyPickupNeeded}
          label="Do you need to schedule key pick-up??"
        />
        {form.isKeyPickupNeeded && (
          <div style={{marginTop: spaceMedium}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DateInput
                  label={'Reservation date'}
                  value={reservationDate}
                  onChange={setReservationDate}
                  disabled={!canEditFields}
                  isLoading={isLoading}
                  min={Math.max(
                    moment().add(1, 'day').startOf('day').toDate(),
                    moment(onboardingOccupancy.leaseFromDate).startOf('day').toDate(),
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectInput
                  onChange={dateStr => setForm({startAt: dateStr})}
                  label={'Reservation time'}
                  isRequired={true}
                  options={options}
                  value={form.startAt || undefined}
                  error={invalidItems.includes('startAt')}
                  isLoading={isLoading}
                  disabled={!canEditFields}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      {canEditFields ? (
        <SaveOrEditFormCTAs onSave={handleSave} onCancel={props.onCancel} hasChanges={formChanged} />
      ) : taskRequiresApproval ? (
        <AcceptOrEditFormCTAs onAccept={markSubmissionCompleted} onEdit={() => setIsEditMode(true)} isDisabled={isLoading} />
      ) : (
        <SaveOrEditFormCTAs onSave={() => setIsEditMode(true)} isEdit={true} />
      )}
    </React.Fragment>
  )
}

export default KeyPickup

KeyPickup.propTypes = FormComponentPropTypes
